// src/components/Services.js
import React from 'react';

const Services = () => {
  return (
    <div>
      <h1>Our Services</h1>
      <ul>
        <li>Web Development</li>
        <li>Mobile App Development</li>
        <li>Cloud Computing</li>
        <li>IT Consulting</li>
      </ul>
    </div>
  );
};

export default Services;
