import React from 'react';
import './About.css'; 

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1>About Us</h1>
        <p>We are EshanIt, a team of passionate IT professionals dedicated to providing innovative solutions for businesses worldwide.</p>
        <p>Our mission is to empower organizations with cutting-edge technology, delivering exceptional results and fostering growth.</p>
        <div className="interactive-section">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>Expertise in the latest technologies</li>
            <li>Customized solutions tailored to your needs</li>
            <li>Excellent customer support</li>
            <li>Proven track record of success</li>
          </ul>
        </div>
      </div>
      <div className="map-container">
        <iframe 
          title="Description of iframe content"
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1958.5202850008502!2d78.06790429442881!3d10.960308071684386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s1044%2F6%20%2CKBS%20Arcade%2C%20Koval%20Main%20Road%2C%20Karur%2C639002!5e0!3m2!1sen!2sin!4v1718273817325!5m2!1sen!2sin" 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        <p className="address">1044/6, KBS Arcade, Koval Main Road, Karur - 639002</p>
      </div>
    </div>
  );
};

export default About;
