// src/components/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import homeImage from '../assets/home.jpg';
import serviceImage1 from '../assets/service1.jpg';
import serviceImage2 from '../assets/service2.jpg';
import serviceImage3 from '../assets/service3.webp';
import serviceImage4 from '../assets/service4.jpeg';
import testimonialImage from '../assets/testimonial.jpg';

const Home = () => {

  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };


  return (
    <div className="home-container">
      <section className="hero-banner">
        <img src={homeImage} alt="IT Service" className="hero-image" />
        <div className="hero-text">
          <h1 className='tagline'>Welcome to Our IT Services</h1>
          <p className='tagpara'>Your trusted partner in IT solutions</p>
        </div>
      </section>




      <section className="services-overview">
        <h2>Our Services</h2>
        <div className="services">
          <div className="service">
            <img src={serviceImage1} alt="Web Development" />
            <h3>Web Development</h3>
            <p>We build responsive and scalable web applications tailored to your business needs.</p>
          </div>
          <div className="service">
            <img src={serviceImage2} alt="Mobile App Development" />
            <h3>Mobile App Development</h3>
            <p>Create innovative and user-friendly mobile applications for Android and iOS platforms.</p>
          </div>
          <div className="service">
            <img src={serviceImage3} alt="Mobile App Development" />
            <h3>Cloud Computing</h3>
            <p>Cloud computing delivers scalable, on-demand IT resources over the internet, reducing costs effectively.</p>
          </div>
          <div className="service">
            <img src={serviceImage4} alt="Mobile App Development" />
            <h3>IT consulting</h3>
            <p>IT consulting provides expert advice, optimizing technology strategies and improving business performance</p>
          </div>
        </div>
      </section>



      
      <section className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonial">
          <img src={testimonialImage} alt="Client Testimonial" />
          <p>"EshanIt transformed our business with their exceptional IT solutions. Highly recommend!"</p>
          <p>- Shiva L, CEO of UdayTech</p>
        </div>
      </section>
      <section className="call-to-action">
        <h2>Get Started with EshanIt Today</h2>
        <p>Contact us to discuss how we can help your business thrive with our expert IT services.</p>
        <button onClick={handleContactClick}>Contact Us</button>
      </section>
    </div>
  );
};

export default Home;
