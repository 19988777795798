import React from 'react';

const Footer = () => {
  return (
    <footer className='footerfont'>
      <p>&copy; 2024 Eshaan IT Solutions Private Limited. All rights reserved.</p>
      <p>Email: eshanitsolution@gmail.com &nbsp;</p>
      <p> Phone: +91 89044-88005</p>
    </footer>
  );
};

export default Footer;
